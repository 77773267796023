require('fslightbox');
require('./phone-input');

import { getMeta, onDomReady } from './helpers';
import { initMobileMenu } from './components/menu';
// Set webpack public asset url
window.__webpack_public_path__ = getMeta('theme-url');

import { Application } from '@hotwired/stimulus';
import AxiosFormController from './controllers/axios-form-controller';
import BlockAsyncController from './controllers/block-async-controller';
import CheckboxToggleController from './controllers/checkbox-toggle-controller';
import CookieBarController from './controllers/cookie-bar-controller';
import DatePickerController from './controllers/date-picker-controller';
import ExpandableController from './controllers/expandable-controller';
import ModalController from './controllers/modal-controller';
import TabController from './controllers/tab-controller';
import ClubController from './controllers/club-controller';
import CheckoutController from './controllers/checkout-controller';
import SwitcherController from './controllers/switcher-controller';
import LeafletMapsController from './controllers/leaflet-maps-controller';
import SwiperController from './controllers/swiper-controller';
import AccordionController from './controllers/accordion-controller';
import SelectController from './controllers/select-controller';
import TooltipController from './controllers/tooltip-controller';
import TimetableController from './controllers/timetable-controller';
import TemplateController from './controllers/template-controller';
import PaginationController from './controllers/pagination-controller';
import FormSubmitController from './controllers/form-submit-controller';
import FiltersController from './controllers/filters-controller';
import LetterCountController from './controllers/letter-count-controller';
import SliderProgressGroupController from './controllers/slider-progress-group-controller';
import CountdownController from './controllers/countdown-controller';
import FreeTrialController from './controllers/free-trial-controller';
import RemoveElementController from './controllers/remove-element-controller';

const application = Application.start();

application.register('block-async', BlockAsyncController);
application.register('axios-form', AxiosFormController);
application.register('checkbox-toggle', CheckboxToggleController);
application.register('cookie-bar', CookieBarController);
application.register('date-picker', DatePickerController);
application.register('modal', ModalController);
application.register('tab', TabController);
application.register('expandable', ExpandableController);
application.register('club', ClubController);
application.register('checkout', CheckoutController);
application.register('switcher', SwitcherController);
application.register('leaflet-map', LeafletMapsController);
application.register('swiper', SwiperController);
application.register('accordion', AccordionController);
application.register('select', SelectController);
application.register('tippy', TooltipController);
application.register('timetable', TimetableController);
application.register('template', TemplateController);
application.register('pagination', PaginationController);
application.register('form-submit', FormSubmitController);
application.register('filters', FiltersController);
application.register('letter-count', LetterCountController);
application.register('slider-progress-group', SliderProgressGroupController);
application.register('countdown', CountdownController);
application.register('free-trial', FreeTrialController);
application.register('remove-element', RemoveElementController);

onDomReady(() => {
	initMobileMenu();

	window.addEventListener('scroll', () => {
		document.body.classList[window.scrollY > 20 ? 'add' : 'remove']('scrolled');
	});

	setViewportHeight();
	window.addEventListener('resize', () => {
		setViewportHeight();
	});
});

function setViewportHeight() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}
